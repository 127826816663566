<template lang="">
<div>
    <waiting-modal :title="title" :status="status" :active="active_waiting" @statusdailog="update_status_wait"></waiting-modal>
    <v-row class=" align-center justify-center d-flex">
        <h1 class="text-purple">คลินิก / ศูนย์บริการ</h1>
        <br>
        
    </v-row>
    <br>
    <v-container>
    
        <v-row class=" align-center d-flex pb-20">
        
            <v-col xl="3" lg="5" sm="8" xs="10" class="mx-auto">
                <v-text-field dense class="bg-white" rounded outlined type="text" v-model="name"  hide-details placeholder="พิมพ์เพื่อค้นหา เช่น ชื่อ, จังหวัด หรือ รหัสไปรษณีย์ "></v-text-field>
                <br>
                <v-row class="overflow-y-auto align-content-start" style="height:500px">
                    <v-col v-for="(provider, index) in provider_list_filter" cols="12" style="margin-bottom: -12px">
                        <v-hover>
                            <v-card class="rounded-xl " elevation="4" style="height: 90px">
                                <v-list-item three-line>
                                  
                                            <v-list-item-content>
                                                <v-list-item-title class="text-h6">
                                                    {{provider.provider_name}}
                                                </v-list-item-title>
                                                <v-list-item-subtitle>
                                                    {{provider.district}}, {{provider.province}} {{provider.postcode}}
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                     
                                  
                                            <v-btn @click="goProvider" class="rounded-xl bg-purple text-white">นัดหมาย</v-btn>
                
                                </v-list-item>
                            </v-card>
                        </v-hover>
                    </v-col>
                </v-row>

            </v-col>
        </v-row>
    </v-container>
</div>
</template>

<script>
import CheckmongHomeBar from "@/components/CheckmongHomeBar.vue";
import WaitingModal from "@/components/WaitingModal.vue";
import {
    APIURL,LIFFID
} from '@/constants'

import Vue from 'vue'
export default {
    name: "medicine_alert",
    components: {
        CheckmongHomeBar,
        WaitingModal,
    },
    data() {
        return {
            name:'',
            active_waiting: false,
            provider_list: [],
            status: '',
            title: '',

        }
    },
    mounted() {
        this.getProviderList()
    },
    async beforeCreate() {
        window.liff.ready
        // await window.liff.init({
        //     liffId: LIFFID
        // }).then(() => {//console.log('done window.liff.init')});
    },
    computed:{
            provider_list_filter: function() {

            // const p = Array.from(this.name).reduce((a, v, i) => `${a}[^${this.name.substr(i)}]*?${v}`, '');
            return this.provider_list.filter(provider => provider.provider_name_location.match(this.name))
            // return this.provider_list.filter(provider => provider.provider_name_location === this.name)
            },
    },
    methods: {
        goProvider(){
            if(window.liff.isInClient()){
                // window.liff.closeWindow();
                // this.$router.push("/login");
                window.open("https://liff.line.me/1657454775-xV2qnykD");
            }
            else{
                window.open("https://lin.ee/nnrjhml");
            }
        },
        update_status_wait(val) {
            this.active_waiting = val
        },
        async getProviderList() {
            this.active_waiting = true
            this.title = '',
                this.status = 'loading'
            await Vue.axios.post(APIURL + '/get_provider_list', {}, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                if (response.data) {
                    this.active_waiting = false
                    //console.log(response.data)
                    this.provider_list = response.data.provider_list;
                    console.log('777777777777777')
                    console.log(this.provider_list)

                } else {
                    this.active_waiting = false
                    //console.log(response)
                }

            })

        },
    },

}
</script>

<style lang="">

</style>
